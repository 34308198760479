import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  justify-content: center;
  align-items: center;
  background-color: white; /* Set background color to black */
`;

const Image = styled.img`
  width: 400px; /* Adjust the size of the image as needed */
`;

const Title = styled.h2`
  font-size: 14px;
  margin-top: 10px;
`;

function App() {
  return (
    <Container>
      <Image src="/early.jpeg" alt="we are so early" />
      <Title> CA: 0xe234D7853F90E17A6bA4346237D608a34891f3d1 </Title>
      <Title> <a href="https://app.uniswap.org/explore/tokens/base/0xe234D7853F90E17A6bA4346237D608a34891f3d1"> BUY ON UNISWAP </a> </Title>
      <Title> <a href="https://dexscreener.com/base/0xe234D7853F90E17A6bA4346237D608a34891f3d1"> DEXSCREENER </a> </Title>
      <Title> <a href="https://t.me/+F-M4b6dEOANlYmQ9"> TELEGRAM </a></Title>
      <Title> <a href="https://x.com/earlyonbase"> TWITTER </a></Title> 
    </Container>
  );
}

export default App;
